import { useState, useRef, useEffect } from 'react'

import emailjs from '@emailjs/browser'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Wpisz poprawy adres email')
    .required('Email jest wymagany'),
  name: yup.string().required('Imię i nazwisko jest wymagane'),
  message: yup.string().required('Treść wiadomości jest wymagana')
})

interface EmailData {
  email: string
  name: string
  message: string
}

export const ContactForm = () => {
  const [isFeedback, setIsFeeback] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
        timer.current = null
      }
    }
  }, [])

  const handleFeedback = () => {
    setIsFeeback(true)
    timer.current = setTimeout(() => {
      setIsFeeback(false)
      setIsError(false)
      setIsSuccess(false)
    }, 6000)
  }

  const sendEmail = (
    { email, name, message }: EmailData,
    resetForm: () => void
  ) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID as string,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID as string,
        { email, name, message },
        process.env.REACT_APP_EMAIL_JS_USER_ID as string
      )
      .then(
        () => {
          setIsSuccess(true)
        },
        () => {
          setIsError(true)
        }
      )
      .finally(() => {
        handleFeedback()
        resetForm()
      })
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      message: ''
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      sendEmail(values, resetForm)
    }
  })

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Typography sx={{ marginBottom: 2 }}>
        Zapraszamy do kontaktu droga telefoniczną lub mailową.
      </Typography>
      <Box sx={{ width: [300, 500] }}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{ paddingBottom: 2 }}
          />
          <TextField
            fullWidth
            id="email"
            name="name"
            label="Imię i nazwisko"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            sx={{ paddingBottom: 2 }}
          />
          <TextField
            fullWidth
            id="message"
            name="message"
            label="Wiadomość"
            placeholder="Wpisz treść wiadomości"
            multiline
            rows={4}
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            sx={{ paddingBottom: 2 }}
          />
          <Button color="primary" variant="contained" fullWidth type="submit">
            Wyślij
          </Button>
        </form>
        {isFeedback && isError && (
          <Alert severity="error">
            <AlertTitle>Błąd</AlertTitle>
            Nie udało nam się wysłać Twojej wiadomości. Spróbuj ponownie później
            lub skontaktuj się telefonicznie.
          </Alert>
        )}
        {isFeedback && isSuccess && (
          <Alert severity="success">
            <AlertTitle>Wysłano</AlertTitle>
            Email został wysłany. Spróbujemy odpowiedzieć jak naszybciej.
          </Alert>
        )}
      </Box>
    </Box>
  )
}
