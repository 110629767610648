import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { ViewWrapper } from '../components/ViewWrapper'

const StyledTypography = styled(Typography)(({ theme }) => ({
  textIndent: 12,
  paddingRight: theme.spacing(2)
}))

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: 520,
  height: '495px',
  margin: theme.spacing(0, 2),
  boxShadow: '0 0 4px rgba(0,0,0,0.5)',
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}))

export const Home = () => {
  return (
    <ViewWrapper title="STRONA GŁÓWNA">
      <Grid container>
        <Grid xs={12} md={6}>
          <StyledTypography>
            Serdecznie zapraszamy Państwa na wypoczynek do Ustronia Morskiego.
            Nasz obiekt znajduje się 200 m od plaży - 5 minut spacerkiem.
            Oferujemy Państwu pokoje 2, 3, 4 osobowe z łazienkami z dostępem do
            kuchni oraz 5 osobowe, drewniane domki letniskowe.
          </StyledTypography>
          <br />
          <StyledTypography>
            Wyposażenie pokoi: sprzęt plażowy, TV, lodówka, czajnik
            bezprzewodowy komplet naczyń- dostęp do kuchni Domki letniskowe: 2
            pokoje, łazienka, aneks kuchenny / lodówka, czajnik, komplet garnków
            i naczyń, kuchenka elektryczna / sprzęt plażowy. Na terenie obiektu
            znajduje się bezpłatny parking, miejsce na grilla, plac zabaw
          </StyledTypography>
        </Grid>
        <Grid
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Image src="/images/home.jpg" alt="Domki letniskowe" />
        </Grid>
      </Grid>
    </ViewWrapper>
  )
}
