import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

import { ViewWrapper } from '../components/ViewWrapper'

const mainTexts = [
  `  Serdecznie zapraszamy Państwa na wypoczynek do Ustronia Morskiego. Nasz
obiekt znajduje się 200 m od plaży - 5 minut spacerkiem.`,
  `Oferujemy Państwu pokoje 2, 3, 4 osobowe z łazienkami z dostępem do
kuchni oraz 5 osobowe, drewniane domki letniskowe.`,
  ` Wyposażenie pokoi: sprzęt plażowy, TV, lodówka, czajnik bezprzewodowy
komplet naczyń- dostęp do kuchni`,
  `  Domki letniskowe: 2 pokoje, łazienka, aneks kuchenny / lodówka, czajnik,
komplet garnków i naczyń, kuchenka elektryczna / sprzęt plażowy.`,
  `Na terenie obiektu znajduje się bezpłatny parking, miejsce na grilla,
plac zabaw dla dzieci.`,
  'Serdecznie zapraszamy na niezapomniane wakacje!'
]

const facility = [
  'kuchnia do dyspozycji',
  'miejsce na grilla',
  'parking',
  'internet',
  'plac zabaw dla dzieci'
]

const rooms = [
  'łazienka',
  'TV',
  'sprzęt plażowy',
  'lodówka',
  'czajnik',
  'aneks kuchenny'
]

export const Offer = () => {
  return (
    <ViewWrapper title="OFERTA">
      {mainTexts.map(text => (
        <>
          <Typography>{text}</Typography>
          <br />
        </>
      ))}

      <Typography variant="h2" sx={{ textAlign: 'center', marginBottom: 2 }}>
        DODATKOWE INFORMACJE
      </Typography>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">Na terenie obiektu:</Typography>
          <List>
            {facility.map(item => (
              <ListItem key={item}>
                <ListItemIcon sx={{ minWidth: 22 }}>
                  <ArrowRightIcon />
                </ListItemIcon>
                <ListItemText>{item}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">Wyposażenie pokoi:</Typography>
          <List>
            {rooms.map(item => (
              <ListItem key={item}>
                <ListItemIcon sx={{ minWidth: 22 }}>
                  <ArrowRightIcon />
                </ListItemIcon>
                <ListItemText>{item}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </ViewWrapper>
  )
}
