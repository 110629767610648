import { ContactForm } from '../components/ContactForm'
import { ViewWrapper } from '../components/ViewWrapper'

export const Contact = () => {
  return (
    <ViewWrapper title="KONTAKT">
      <ContactForm />
    </ViewWrapper>
  )
}
