import Box from '@mui/material/Box'
import Gallery from 'react-grid-gallery'

const thumbnailWidth = 320
const thumbnailHeight = 320
const imagesNumber = 19
const images = new Array(imagesNumber)
  .fill('/images/')
  .map((path: string, index) => ({
    src: `${path}${index + 1}-min.jpg`,
    thumbnail: `${path}${index + 1}-thumbnail.jpg`,
    thumbnailWidth,
    thumbnailHeight
  }))

export const PhotoGallery = () => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Gallery
        enableImageSelection={false}
        images={images}
        rowHeight={320}
        margin={8}
      />
    </Box>
  )
}
