import HomeIcon from '@mui/icons-material/Home'
import MailIcon from '@mui/icons-material/Mail'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

const Wrapper = styled(Grid)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(2, 0)
  }
}))

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1)
}))

const IconContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left'
  }
}))

const PhoneContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
})

export const SideBar = () => {
  return (
    <Wrapper container id="sidebar">
      <Grid item xs={12}>
        <Title variant="h4">KONTAKT</Title>
        <Grid container sx={{ paddingBottom: 1 }}>
          <IconContainer item xs={2}>
            <HomeIcon fontSize="large" />
          </IconContainer>
          <Grid item xs={10}>
            <a href="https://goo.gl/maps/uwxDwoAxofLwCPcL8">
              <Typography>Wiejska 13</Typography>
              <Typography>78-111 Ustronie Morskie</Typography>
              <Typography>woj. zachodniopomorskie</Typography>
            </a>
          </Grid>
        </Grid>
        <Grid container sx={{ paddingBottom: 1 }}>
          <IconContainer item xs={2}>
            <PhoneAndroidIcon fontSize="large" />
          </IconContainer>
          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
            {isMobile ? (
              <PhoneContainer>
                <a href="tel:604907922">
                  <Typography>604 907 922</Typography>
                </a>
              </PhoneContainer>
            ) : (
              <PhoneContainer>
                <Typography>604 907 922</Typography>
              </PhoneContainer>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <IconContainer item xs={2}>
            <MailIcon fontSize="large" />
          </IconContainer>
          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
            <Link to="/kontakt">
              <Typography>Napisz do nas!</Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Title variant="h4" sx={{ marginTop: 2 }}>
          LOKALIZACJA
        </Title>
        <Grid container>
          <Grid item xs={12}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2333.2073897168634!2d15.747287951921262!3d54.21181698007219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47003bf578cf972b%3A0xbcb37dfb0297c38d!2sPokoje%20U%20Krystyny!5e0!3m2!1sen!2spl!4v1641237481326!5m2!1sen!2spl"
              width="100%"
              height="230"
              style={{ border: 0 }}
              loading="lazy"
              title="Mapa"
            />
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  )
}
