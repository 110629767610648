import { useState } from 'react'

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import MuiIconButton from '@mui/material/IconButton'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link } from 'react-router-dom'

const routes = [
  {
    title: 'STRONA GŁÓWNA',
    route: '/'
  },
  {
    title: 'OFERTA',
    route: '/oferta'
  },
  {
    title: 'GALERIA',
    route: '/galeria'
  },
  {
    title: 'KONTAKT',
    route: '/kontakt'
  }
]

const Wrapper = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: 'fit-content',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: `linear-gradient(180deg, #b0b0b0 0%, ${theme.palette.primary.main} 46%)`,
  backgroundColor: theme.palette.primary.main,
  boxShadow: '0 1px 13px rgb(0 0 0 / 40%)'
}))

const Header = styled(Box)(() => ({
  maxWidth: 800,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

const Image = styled('img')(() => ({
  height: 'auto',
  maxWidth: 200
}))
const NavigationWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 860,
    marginTop: theme.spacing(2),
    justifyContent: 'space-evenly'
  }
}))

const IconButton = styled(MuiIconButton)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
})

const ExpandIcon = styled(ArrowDropUpIcon)(
  ({ props: { isExpanded } }: { props: { isExpanded: boolean } }) => ({
    transition: 'transform 0.4s ease',
    transform: `rotate(${isExpanded ? 0 : '180deg'})`
  })
)

const LinkItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddding: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    '&:hover': {
      transform: 'scale(1.1)'
    }
  }
}))

const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Wrapper item xs={12}>
      <Link to="/">
        <Header>
          <Image src="/logo.svg" alt="logo" />
          <Typography
            variant="h3"
            component="h1"
            sx={{ textAlign: 'center', marginTop: 1 }}
          >
            Pokoje Gościnne Krystyna i Domki Letniskowe
          </Typography>
        </Header>
      </Link>
      {isUpMd ? (
        <NavigationWrapper container>
          {routes.map(({ title, route }) => (
            <LinkItem key={title} item xs={12} md="auto">
              <Link to={route}>
                <Typography variant="h5" component="h3" sx={{ padding: 1 }}>
                  {title}
                </Typography>
              </Link>
            </LinkItem>
          ))}
        </NavigationWrapper>
      ) : (
        <NavigationWrapper container>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <IconButton
              size="large"
              onClick={() => setIsExpanded(isExpanded => !isExpanded)}
              sx={{ color: 'white' }}
            >
              <Typography>MENU</Typography>
              <ExpandIcon fontSize="large" props={{ isExpanded }} />
            </IconButton>
          </Grid>
          <Collapse in={isExpanded} timeout={1000} sx={{ width: '100%' }}>
            {routes.map(({ title, route }) => (
              <Link key={title} to={route} style={{ width: '100%' }}>
                <LinkItem item xs={12} md={3}>
                  <Typography variant="h5" sx={{ padding: 1 }}>
                    {title}
                  </Typography>
                </LinkItem>
              </Link>
            ))}
          </Collapse>
        </NavigationWrapper>
      )}
    </Wrapper>
  )
}

export { Navbar, routes }
