import type { ReactNode } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet'

type ViewWrapperProps = {
  title: string
  children: ReactNode
}

export const ViewWrapper = ({ title, children }: ViewWrapperProps) => {
  return (
    <Box
      component="main"
      sx={{ maxWidth: '1440px', marginRight: 0, marginLeft: 'auto' }}
    >
      <Helmet>
        <title>{title} - Pokoje Gościnne Krystyna i Domki Letniskowe</title>
      </Helmet>
      <Typography
        variant="h2"
        sx={{ textAlign: ['center'], marginBottom: 2, marginTop: 1 }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  )
}
