import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

const Wrapper = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: 90,
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up('sm')]: {
    height: 65
  }
}))

const Image = styled('img')(() => ({
  width: 75,
  height: 25
}))

export const Footer = () => {
  return (
    <Wrapper item xs={12}>
      <Image src="/logo.svg" />
      <Typography
        sx={{ textAlign: 'center' }}
      >{`Pokoje Gościnne Krystyna i Domki Letniskowe © ${new Date().getFullYear()}`}</Typography>
    </Wrapper>
  )
}
