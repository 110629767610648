import type { ReactNode } from 'react'

import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { Redirect, useLocation } from 'react-router-dom'

import { Footer } from './Footer'
import { Navbar, routes } from './Navbar'
import { SideBar } from './Sidebar'

const Wrapper = styled(Grid)(({ theme }) => ({
  minHeight: '100vh',
  position: 'relative',
  paddingBottom: 90,
  alignContent: 'flex-start',
  [theme.breakpoints.up('sm')]: {
    paddingBottom: 65
  }
}))

type LayoutProps = {
  children: ReactNode
}
export const Layout = ({ children }: LayoutProps) => {
  const { pathname } = useLocation()

  if (!routes.some(({ route }) => route === pathname)) {
    return <Redirect to="/" />
  }

  return (
    <Wrapper container spacing={0}>
      <Navbar />
      <Grid
        item
        xs={12}
        md={8}
        lg={9}
        sx={{ padding: 2, maxWidth: 1200, margin: '0 auto' }}
      >
        {children}
      </Grid>

      <Grid item xs={12} md={4} lg={3} sx={{ padding: 2, height: '100%' }}>
        <SideBar />
      </Grid>

      <Footer />
    </Wrapper>
  )
}
