import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let theme = createTheme({
  palette: {
    primary: {
      main: '#007096'
    }
  },
  typography: {
    fontFamily: ['Roboto Slab', 'serif'].join(),
    h3: {
      fontFamily: ['Dancing Script', 'cursive'].join()
    }
  }
})

theme = responsiveFontSizes(theme)

export { theme }
