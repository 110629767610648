import { PhotoGallery } from '../components/PhotoGallery'
import { ViewWrapper } from '../components/ViewWrapper'

export const Gallery = () => {
  return (
    <ViewWrapper title="GALERIA">
      <PhotoGallery />
    </ViewWrapper>
  )
}
