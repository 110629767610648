import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { Layout } from './components/Layout'
import { theme } from './styles/theme'
import { Home, Offer, Gallery, Contact } from './views'

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Layout>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/oferta" exact>
              <Offer />
            </Route>
            <Route path="/galeria" exact>
              <Gallery />
            </Route>
            <Route path="/kontakt" exact>
              <Contact />
            </Route>
          </Layout>
        </Switch>
      </Router>
    </ThemeProvider>
  )
}
